<template>
  <main>
    <div class="modal-wrap">
      <h1 class="tit-h1 d-flex">
        약관관리
        <!-- 창 닫기 아이콘 -->
        <div class="ml-auto align-self-center">
          <v-icon class="svg-close20" x-small @click="$emit('hide')"></v-icon>
        </div>
      </h1>
      <!-- 입력 항목 -->
      <div class="modal-box flex-grow-1" style="padding: 0 10px 10px 10px">
        <div class="box-ct mt-2">
          <div class="table-form">
            <table>
              <caption class="hide">
                항목
              </caption>
              <colgroup>
                <col width="70px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row" class="text-center">
                    약관제목
                  </th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      v-model="TERM_TITLE"
                      name="TERM_TITLE"
                      readonly
                      outlined
                      hide-details
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="text-center">
                    약관내용
                  </th>
                  <td>
                    <v-textarea
                      class="textarea-type0"
                      v-model="TERM_CONT"
                      name="TERM_CONT"
                      counter
                      no-resize
                      readonly
                      outlined
                      hide-details
                      height="65vh"
                    >
                    </v-textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="text-center mt-3">
          <v-btn outlined class="btn-default" @click="$emit('hide')">확인</v-btn>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import {mixin} from "@/mixin/mixin";

export default {
  name: "MENU_CAP0541", //name은 'MENU_' + 파일명 조합
  components: {},
  mixins:[mixin],
  props : {
    param: {
      type: Object,
    },
  },
  data() {
    return {
      HEADERResult: '',
      DATAResult  : '',
      
      HEADER_TYPE     : 'BIZ_SERVICE',
      HEADER_GRID_ID  : '',
      HEADER_ROW_CNT  : '',
      HEADER_PAGES_CNT: '',
      headers         : {},
      
      URLData : '',
      sendData: {},

      // 전달 받은 값 담을 변수
      BUSS_CODE: '', // 사업지코드
      TERM_TYPE: '', // 약관유형

      // 팝업 내용
      TERM_TITLE: '', // 약관제목
      TERM_CONT : '', // 약관내용

      // 기타
      TERM_TYPE_CD: '', // 약관유형 담을 변수
      USER_ID     : '', // 사용자아이디

      // 메시지
      cap0541Msg: {
        termChk: '해당 약관 정보가 없습니다.',
      },

      META_STR: '<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">',
    };
  },
  methods: {
    // 상세 조회
    async init() {
      $('head').append(this.META_STR);

      this.cleanData(); // 초기화
      
      let requestData = {
        headers: this.initHeaders,
        sendData: {},
      }
      
      // 부모 창에서 넘어온 값 담기
      this.BUSS_CODE = this.param.bussCode;
      this.TERM_TYPE = this.param.termType;
      this.TERM_TYPE_CD = this.TERM_TYPE === 'prsn' ? 'TERM_TYPE_CD_01' : 'TERM_TYPE_CD_02';

      requestData.headers["URL"] = "/api/buss/term-manage/detail";
      requestData.headers["SERVICE"] = "buss.term-manage";
      requestData.headers["METHOD"] = "detail";
      requestData.headers["TWB_SQL_ID"] = "selectTermDetail";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
      requestData.sendData["TERM_TYPE_CD"] = this.TERM_TYPE_CD;

      let response = await this.common_postCall(requestData, false);
      console.log(response);

      this.result(response);
    },

    // 약관내용 조회 결과
    getGridDataDetailCallBack(response) {
      let detailData = response.DATA;

      if(detailData.length > 0) {
        this.TERM_TITLE = this.decode(detailData[0].TERM_TITLE);
        this.TERM_CONT = this.decode(detailData[0].TERM_CONT);
      }else {
        // this.common_alert(this.cap0541Msg.termChk, "chk");
        this.$emit('hide'); // 팝업 닫기
        this.common_toast(this.cap0541Msg.termChk);
        return;
      }
    },

    // 결과 확인
    result(response) {
      if(!this.mixin_isEmpty(response)) {
        this.getGridDataDetailCallBack(response);
      }
    },

    // 초기화
    cleanData() {
      this.TERM_TITLE = '';
      this.TERM_CONT = '';
    },

    // 특수기호 변환
    decode(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }

      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }

      //순서바뀌면 안나옴
      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("<p>", "\n");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");

      return strText;
    },
  },
  async mounted() {
    this.USER_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;

    this.init();
  },
  computed: {
    initHeaders() {
      return this.headers = {
        "SERVICE": "",
        "METHOD": "",
        "TWB_SQL_ID": "",
        "TYPE": this.HEADER_TYPE,
      };
    },
  },
};
</script>
<style>

</style>