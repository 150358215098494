import { render, staticRenderFns } from "./CAP0570M.vue?vue&type=template&id=4f60f5f4&scoped=true&"
import script from "./CAP0570M.vue?vue&type=script&lang=js&"
export * from "./CAP0570M.vue?vue&type=script&lang=js&"
import style0 from "./CAP0570M.vue?vue&type=style&index=0&id=4f60f5f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f60f5f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCheckbox,VDialog,VIcon})
